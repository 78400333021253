<template>
    <div>
      <!-- loading state -->
      <template v-if="isLoading">

            <div class="text-center">
                <b-spinner variant="primary" label="Loading" />
            </div>

      </template>

      <!-- content section -->
      <template v-else>
          <b-card title="Convenios y Acuerdos">
              <b-card-text>
                  Los <strong>Convenios de confidencialidad y Acuerdos de No divulgación</strong> generados por la Cooperativa 23 de Julio están sujetos a actualizaciones. Cada vez que se sube un nuevo archivo, este reemplazará automáticamente al convenio existente en toda la aplicación.
              </b-card-text>
              <div>
                  <!-- Estado vacio por defecto del sistema -->
                  <div v-if="vacioConvenio">
                      <b-alert show variant="warning">
                          <div class="alert-body">
                              <span class="ml-25">Se requiere proporcionar el documento correspondiente al Convenio de Confidencialidad y Acuerdo de No Divulgación.</span>
                          </div>

                      </b-alert>
                      <b-button variant="primary" class="mt-0" @click="handleDocumentNdaUpload">
                          Cargar Convenio de Condidencialidad
                      </b-button>
                  </div>
                  <!-- Si existe un archivo se presenta el archivo subido -->
                  <div class="demo-spacing-0" v-if="existConvenio">

                      <b-alert show variant="primary">
                          <div class="alert-body">
                              <span class="ml-25">Existe un documento en la plataforma, el cual fue subido el {{ creationDateNda }}.</span>
                          </div>
                          <div class="alert-body">
                              <feather-icon
                                  class="mr-25"
                                  icon="FileTextIcon"
                              />
                              <span class="ml-25">{{ fileNameNda }}</span>
                          </div>
                      </b-alert>

                      <b-button
                          variant="primary"
                          class="mt-1"
                          @click="handleDocumentNdaEdit"
                          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      >
                          Cargar Convenio de Confidencialidad
                      </b-button>


                  </div>
                  <!-- Styled -->
                  <b-form @submit.prevent="SubmitConvenios" v-if="uploadConvenio" >
                  <b-form-file
                      ref="file-input-convenios"
                      v-model="file"
                      placeholder="Elije un archivo o arrástralo hasta aquí..."
                      drop-placeholder="Arrástralo hasta aquí..."
                  />
                      <b-button type="submit" variant="primary" class="mt-1" :disabled="isBusyConvenio">
                          <template v-if="isBusyConvenio">
                              <b-spinner small ></b-spinner>
                              Enviando..
                          </template>
                          <template v-else >
                              Enviar Convenio
                          </template>

                      </b-button>

                      <b-button variant="secondary" class="mt-1 ; ml-1" @click="handleDocumentNdaCancel">
                          <template>
                              Cancelar
                          </template>
                      </b-button>


                  </b-form>
              </div>

              <b-card-text style="margin-top: 25px" >
                  Los <strong>Acuerdos de Responsabilidad de Claves</strong> generados por la Cooperativa 23 de Julio están sujetos a actualizaciones. Cada vez que se sube un nuevo archivo, este reemplazará automáticamente al convenio existente en toda la aplicación.
              </b-card-text>
              <div>

                  <!-- Estado vacio por defecto del sistema -->
                  <div v-if="vacioAcuerdo">
                      <b-alert show variant="warning">
                          <div class="alert-body">
                              <span class="ml-25">Se requiere proporcionar el documento correspondiente al Acuerdo de Responsabilidad de Claves. </span>
                          </div>

                      </b-alert>
                      <b-button variant="primary" class="mt-0" @click="handleDocumentKraUpload">
                          Subir
                      </b-button>
                  </div>

                  <div class="demo-spacing-0" v-if="existAcuerdo">
                      <b-alert show variant="primary">
                          <div class="alert-body">
                              <span class="ml-25">Existe un documento en la plataforma, el cual fue subido el {{ creationDateKra }}.</span>
                          </div>
                          <div class="alert-body">
                              <feather-icon
                                  class="mr-25"
                                  icon="FileTextIcon"
                              />
                              <span class="ml-25">{{ fileNameKra }}</span>
                          </div>
                      </b-alert>
                      <b-button
                          variant="primary"
                          class="mt-1"
                          @click="handleDocumentKraEdit"
                          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      >
                          Cargar Acuerdo de Responsabilidad
                      </b-button>
                  </div>
                  <!-- Styled -->
                  <b-form @submit.prevent="SubmitAcuerdos" v-if="uploadAcuerdo">
                      <b-form-file
                          ref="file-input-acuerdos"
                          v-model="file"
                          placeholder="Elije un archivo o arrástralo hasta aquí..."
                          drop-placeholder="Arrástralo hasta aquí..."
                      />
                      <b-button type="submit" variant="primary" class="mt-1">
                          <template v-if="isBusyAcuerdo">
                              <b-spinner small ></b-spinner>
                              Enviando..
                          </template>
                          <template v-else >
                              Enviar Acuerdo
                          </template>
                      </b-button>
                      <b-button variant="secondary" class="mt-1 ml-1" @click="handleDocumentKraCancel">
                          <template>
                              Cancelar
                          </template>
                      </b-button>
                  </b-form>
              </div>
          </b-card>
        </template>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {BCard, BCardText, BFormFile, BForm, BButton, BSpinner, BAlert} from "bootstrap-vue";

import { getUserData } from '@/modules/auth/utils'

export default {
    components: {BAlert, BSpinner, BFormFile, BCardText,BForm,BButton, BCard},
    data() {
        return {
            file: [],
            isLoading: false,
            fileNameNda: "",
            creationDateNda: "",
            fileNameKra: "",
            creationDatekra: "",
            maxFileSize: 2 * 1024 * 1024, // 2MB en bytes
            allowedFileTypes: ['application/pdf'],
            isBusyConvenio: false,
            isBusyAcuerdo: false,
            uploadConvenio: false,
            existConvenio: false,
            vacioConvenio: true,
            vacioAcuerdo: true,
            uploadAcuerdo: false,
            existAcuerdo: false,
        };
    },

    methods: {
        ...mapActions('file-module', ['postFileNda','postFileKra','getFindDoc']),

        async handleDocumentNdaCancel(){
            this.vacioConvenio = false;
            this.uploadConvenio = false;
            this.existConvenio = true;
        },
        // para iniciar carga de documentos
        async handleDocumentNdaUpload(){
            this.vacioConvenio = false;
            this.uploadConvenio = true;
        },
        // editar documento subido
        async handleDocumentNdaEdit(){
            this.uploadConvenio = true;
            this.existConvenio = false;
        },

        async handleDocumentKraCancel(){
            this.vacioAcuerdo = false;
            this.uploadAcuerdo = false;
            this.existAcuerdo = true;
        },

        // para iniciar carga de documentos
        async handleDocumentKraUpload(){
            this.vacioAcuerdo = false;
            this.uploadAcuerdo = true;
        },
        // editar documento subido
        async handleDocumentKraEdit(){
            this.uploadAcuerdo = true;
            this.existAcuerdo = false;
        },

        async existDocument(){
            const params = { tipo : 'NDA' }
            const response = await this.getFindDoc(params)

        },

        async SubmitConvenios(event) {
            event.preventDefault();
            let formData = new FormData();
            formData.append('file', this.file);
            //const params = { file: formData};
            this.isBusyConvenio = true;
            if (this.file && this.file.size > this.maxFileSize) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Documento',
                        icon: 'XCircleIcon',
                        text: 'Registro excede el limite de 2MB.',
                        variant: 'danger'
                    },
                })
                this.$refs['file-input-convenios'].reset()
                return
            }

            if (!this.allowedFileTypes.includes(this.file.type)) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Documento',
                        icon: 'XCircleIcon',
                        text: 'Solo se permiten archivos en formato PDF. Por favor, elija otro archivo.',
                        variant: 'danger'
                    },
                })
                this.$refs['file-input-convenios'].reset()
                return
            }


            await this.postFileNda(formData)

          this.$toast({
                component: ToastificationContent,
                props: {
                     title: 'Documentos',
                     icon: 'CheckIcon',
                     text: '¡Convenio fue subido con exito!',
                     variant: 'success'
                  },
          })

          this.isBusyConvenio = false;
          this.existConvenio = true;
          this.uploadConvenio = false;
          await this.LoadData()

        },

        async SubmitAcuerdos(event) {
            //console.log(event)
            event.preventDefault();
            let formData = new FormData();
            formData.append('file', this.file);
            this.isBusyAcuerdo = true;

            if (this.file && this.file.size > this.maxFileSize) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Documento',
                        icon: 'XCircleIcon',
                        text: 'Registro excede el limite de 2MB.',
                        variant: 'danger'
                    },
                })
                this.$refs['file-input-acuerdos'].reset()
                return
            }

            if (!this.allowedFileTypes.includes(this.file.type)) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Documento',
                        icon: 'XCircleIcon',
                        text: 'Solo se permiten archivos en formato PDF. Por favor, elija otro archivo.',
                        variant: 'danger'
                    },
                })
                this.$refs['file-input-acuerdos'].reset()
                return
            }

            await this.postFileKra(formData)

            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Documentos',
                    icon: 'CheckIcon',
                    text: '¡Acuerdo fue subido con exito!',
                    variant: 'success'
                },
            })

            this.isBusyAcuerdo = false;
            await this.LoadData()
        },

        async LoadData(){

            this.isLoading = true
            //const response = await this.existDocument();
            const paramsNda = { tipo : 'NDA' }
            const responseNda = await this.getFindDoc(paramsNda)

            const paramsKra = { tipo : 'KRA' }
            const responseKra = await this.getFindDoc(paramsKra)

            if(responseNda.data.response == 'OK'){
                this.fileNameNda = responseNda.data.fileName;
                this.creationDateNda = responseNda.data.creationDate.toString();
                this.vacioConvenio = false
                this.existConvenio = true
            }
            if(responseKra.data.response == 'OK'){
                this.fileNameKra = responseKra.data.fileName;
                this.creationDateKra = responseKra.data.creationDate.toString();
                this.vacioAcuerdo = false
                this.existAcuerdo = true
            }
            this.isLoading = false
        }
    },
    async created() {
        await this.LoadData()
    }
}
</script>
